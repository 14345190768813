<template>
  <div class="productcontent-block__content">
    <div class="product-info">
      <a v-if="linkUrl" :href="linkUrl">{{ linkText }}</a>
      <button v-if="selectedImage" class="btn btn-default dropdown-toggle active active--mob" type="button"
              id="productimagelist" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
        <div>
          <div :style="{ backgroundImage: 'url(\'' + selectedImage.imageUrl + '\')' }"></div>
        </div>
        <span>{{ selectedImage.text }}</span>
      </button>
      <ul class="dropdown-menu" aria-labelledby="productimagelist">
        <li v-for="(image, idx) in images" v-bind:key="idx" v-on:click="imageClick(idx)"
            v-bind:class="{ active: imageSelected(idx) }">
          <a>
            <div>
              <div :style="{ backgroundImage: 'url(\'' + image.imageUrl + '\')' }"></div>
            </div>
            <span>{{ image.text }}</span>
          </a>

        </li>
      </ul>
    </div>

    <div class="product__img">
      <img :src="selectedImage.imageUrl" :alt="selectedImage.text"/>
    </div>
  </div>
</template>
<script>
import apiService from "../services/apiservice";

export default {
  name: "ProductImage",
  props: {
    images: {
      type: Array
    },
    linkText: {
      type: String
    },
    linkUrl: {
      type: String
    }
  },
  computed: {
    selectedImage: {
      get: function () {
        return this.images.length > this.selectedIndex ? this.images[this.selectedIndex] : null;
      }
    }
  },
  methods: {
    imageClick: function (idx) {
      this.selectedIndex = idx;
    },
    imageSelected: function (idx) {
      return idx === this.selectedIndex;
    }
  },
  data: function () {
    return {
      selectedIndex: 0
    }
  }
}
</script>
<style lang="scss"></style>