import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import articleListingModule from "./index.articlelisting";

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

// one store for entire application
export default new Vuex.Store({
    strict: debug,
    modules: {
        articleListingModule
    },
    plugins: [createPersistedState()]
})