import * as types from "./mutation-types.articlelisting";

export default {
    [types.SET_CATEGORY_SELECTED_ID](state, { categoryId }) {
        state.selectedCategoryId = categoryId;
        state.categories.forEach(function (item) { item.Selected = item.Id === categoryId });
        state.selectedCategory = state.categories.find(function (item) { return item.Id === categoryId });
    },
    [types.SET_CATEGORIES](state, { categories }) {
        state.categories = categories;
    }
};