var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("transition", { attrs: { name: "modal" } }, [
    _c("div", { staticClass: "modal-mask" }, [
      _c("div", { staticClass: "modal-wrapper" }, [
        _c(
          "div",
          {
            staticClass: "modal-container",
            style: { width: _vm.width + "px" },
          },
          [
            _c("button", {
              staticClass: "fa fa-times",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  return _vm.$emit("closemodal")
                },
              },
            }),
            _vm._v(" "),
            _vm._t("content"),
          ],
          2
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }