var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.searchtext,
          expression: "searchtext",
        },
      ],
      staticClass: "form-control",
      attrs: { type: "text", id: _vm.contentguid },
      domProps: { value: _vm.searchtext },
      on: {
        input: function ($event) {
          if ($event.target.composing) return
          _vm.searchtext = $event.target.value
        },
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }