var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    [
      _vm.showButton
        ? _c(
            "a",
            {
              staticClass: "btn btn-default",
              attrs: { href: "#" },
              on: { click: _vm.playVideo },
            },
            [
              _c("span", {
                staticClass: "glyphicon glyphicon-play-circle",
                attrs: { "aria-hidden": "true" },
              }),
              _vm._v(" " + _vm._s(_vm.videotext) + "\n    "),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "modal",
        {
          attrs: { visible: _vm.modalopen, width: "800" },
          on: { hide: _vm.hideModal, closemodal: _vm.hideModal },
        },
        [
          _c(
            "div",
            {
              staticClass: "embed-responsive embed-responsive-16by9",
              attrs: { slot: "content" },
              slot: "content",
            },
            [
              _vm.videofiles.length > 0
                ? _c(
                    "video",
                    {
                      staticClass:
                        "video-with-overlay__video embed-responsive-item",
                      attrs: { controls: "", playsinline: "" },
                    },
                    _vm._l(_vm.videofiles, function (item, idx) {
                      return _c("source", {
                        key: idx,
                        attrs: { "data-src": item.Url, type: item.Type },
                      })
                    }),
                    0
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("iframe", {
                staticClass: "embed-responsive-item",
                attrs: {
                  id: _vm.playerid,
                  src: _vm.iframeurl,
                  marginwidth: "0",
                  marginheight: "0",
                  scrolling: "no",
                  frameborder: "0",
                  allowfullscreen: "true",
                },
              }),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }