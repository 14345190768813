import { polyfill } from "es6-promise";
import "es6-promise/auto";
import "jquery";
import "jquery-validation";
import "jquery-validation-unobtrusive";
import "bootstrap-sass";
import "jasny-bootstrap/dist/js/jasny-bootstrap";
import "lazysizes";
import "jquery-match-height";
import "spin.js";
import "spin.js/jquery.spin";
import "typeit";
import "./application.js";
import "./gtm.js";
import "./card-block.js";

import Vue from "vue";
import { mapActions } from "vuex";
import VeeValidate, { Validator } from "vee-validate";
import VueNumeric from "./vue/plugins/vue-numeric";
import VueModal from "./vue/plugins/vue-modal";
import store from "./vue/store/index";
import articleFilter from "./vue/components/articlefilter";
import articleListing from "./vue/components/articlelisting";
import contactUs from "./vue/components/contactus";
import productAr from "./vue/components/productar";
import productContent from "./vue/components/productcontent";
import productImage from "./vue/components/productimage";
import productImage2 from "./vue/components/productimage2";

import messagesDe from "./vue/veevalidate/de.js";
import messagesEn from "./vue/veevalidate/en.js";
import messagesEs from "./vue/veevalidate/es.js";
import messagesKo from "./vue/veevalidate/ko.js";
import messagesNl from "./vue/veevalidate/nl.js";
import messagesRu from "./vue/veevalidate/ru.js";
import messagesSv from "./vue/veevalidate/sv.js";
import messagesZh from "./vue/veevalidate/zh_CN.js";

const veemessages = {
    de: messagesDe,
    en: messagesEn,
    es: messagesEs,
    ko: messagesKo,
    nl: messagesNl,
    ru: messagesRu,
    sv: messagesSv,
    zh: messagesZh
};

Vue.use(VeeValidate, {
    errorBagName: "vErrors"
});

polyfill();

// ReSharper disable once ConstructorCallNotUsed
new Vue({
    el: "#siteWrapper",
    components: {
        vuenumeric: VueNumeric,
        modal: VueModal,
        articlefilter: articleFilter,
        articlelisting: articleListing,
        contactus: contactUs,
        productar: productAr,
        productcontent: productContent,
        productimage: productImage,
        productimage2: productImage2,
    },
    store,
    created() {
        const twoLetterCode = document.getElementsByTagName("html")[0].getAttribute("lang");
        Validator.localize(twoLetterCode, veemessages[twoLetterCode]);
    }
});