var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "productcontent-block__content" }, [
    _c("div", { staticClass: "product-info" }, [
      _vm.linkUrl
        ? _c("a", { attrs: { href: _vm.linkUrl } }, [
            _vm._v(_vm._s(_vm.linkText)),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.selectedImage
        ? _c(
            "button",
            {
              staticClass: "btn btn-default dropdown-toggle active active--mob",
              attrs: {
                type: "button",
                id: "productimagelist",
                "data-toggle": "dropdown",
                "aria-haspopup": "true",
                "aria-expanded": "true",
              },
            },
            [
              _c("div", [
                _c("div", {
                  style: {
                    backgroundImage:
                      "url('" + _vm.selectedImage.imageUrl + "')",
                  },
                }),
              ]),
              _vm._v(" "),
              _c("span", [_vm._v(_vm._s(_vm.selectedImage.text))]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "ul",
        {
          staticClass: "dropdown-menu",
          attrs: { "aria-labelledby": "productimagelist" },
        },
        _vm._l(_vm.images, function (image, idx) {
          return _c(
            "li",
            {
              key: idx,
              class: { active: _vm.imageSelected(idx) },
              on: {
                click: function ($event) {
                  return _vm.imageClick(idx)
                },
              },
            },
            [
              _c("a", [
                _c("div", [
                  _c("div", {
                    style: { backgroundImage: "url('" + image.imageUrl + "')" },
                  }),
                ]),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(image.text))]),
              ]),
            ]
          )
        }),
        0
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "product__img" }, [
      _c("img", {
        attrs: { src: _vm.selectedImage.imageUrl, alt: _vm.selectedImage.text },
      }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }