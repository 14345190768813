<template>
	<div class="contact">
		<div class="contact__locations-container">

			<form class="contact__locations container" method="post" @submit="searchMe">
				<div class="nav">
					<button class="btn btn-default dropdown-toggle active active--mob" type="button" id="locations" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
						{{activetitle}}
					</button>
					<ul class="" aria-labelledby="locations">
						<li>
							<a href="#" @click="showHideArea" data-area="all" :data-title="content.ContactUsAllTitle" v-bind:class="{ active: isActive('all') }">{{content.ContactUsAllTitle}}</a>
						</li>
						<li>
							<a href="#" @click="showHideArea" data-area="Americas" :data-title="content.ContactUsAmericaTitle" v-bind:class="{ active: isActive('Americas') }">{{content.ContactUsAmericaTitle}}</a>
						</li>
						<li>
							<a href="#" @click="showHideArea" data-area="Asia-Pacific" :data-title="content.ContactUsAsiaPacificTitle" v-bind:class="{ active: isActive('Asia-Pacific') }">{{content.ContactUsAsiaPacificTitle}}</a>
						</li>
						<li>
							<a href="#" @click="showHideArea" data-area="Europe" :data-title="content.ContactUsEuropeTitle" v-bind:class="{ active: isActive('Europe') }">{{content.ContactUsEuropeTitle}}</a>
						</li>
					</ul>
					<div class="input">
						<button type="submit"><img alt="search" src="/src/images/SearchBlue.svg" /></button>
						<input type="text" v-model="searchtext" v-bind:placeholder="content.SearchPlaceholder" />
						<button type="submit" class="mobile"><img alt="search" src="/src/images/ArrowBlue.svg" /></button>
					</div>
				</div>

			</form>
		</div>
		<modal :visible="modalopen" @hide="hideModal" width="800" @closemodal="hideModal">
			<div slot="content">
				<h3>{{content.OfficesIn}} {{modalcontent.Name}}</h3>
				<div class="row">
					<div class="col-xs-8">
						<div class="row">
							<div class="col-xs-12" v-for="(item, idx) in modalcontent.Items" v-bind:key="idx">
								<p><strong>{{item.Title}}</strong></p>
								<div class="row">
									<div class="col-xs-6 address address--1">
										<div v-html="item.AddressColumn1"></div>
										<p>{{item.Country}}</p>
										<a href="#" class="find" @click="findOnMap(item.marker)">{{content.FindOnMap}}</a>
									</div>
									<div class="col-xs-6 address address--2">
										<div v-html="item.AddressColumn2"></div>
										<a :href="item.HomeUrl" target="_blank">{{item.HomeUrl}}</a>
										<a href="#" class="find find--mobile" @click="findOnMap(item.marker)">{{content.FindOnMap}}</a>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-xs-4 collapse" id="collapseTwo" aria-labelledby="headingTwo" data-parent="#accordion">
						<div id="accordion">
							<div class="card">
								<div class="card-header" id="headingTwo">
									<h5 class="mb-0">
										<button class="btn btn-link" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
											{{content.UseContactForm}}
										</button>
									</h5>
								</div>
								<div>
									<div class="card-body">
										<form method="post" @submit="sendMessage">

											<div class="alert alert-success" v-if="sendsuccess">
												{{smtpsuccess}}
											</div>
											<div class="alert alert-danger" v-if="sendfailure">
												{{smtperror}}
											</div>

											<p>{{content.ContactUsThankYouHeader}}</p>
											<div class="checkbox-container">
												<input type="checkbox" id="checkboxterms" name="agreeterms" v-model="agreeterms" v-validate.initial="{ isEqual: true }" />
												<label for="checkboxterms" v-html="content.ContactUsAgreeTerms">
												</label>
												<span>{{ vErrors.first('agreeterms') }}</span>
											</div>

											<div class="form-group">
												<label>
													Name
													<input type="text" name="name" v-model="contact.name" class="form-control" v-validate="'required'" />
												</label>
												<span>{{ vErrors.first('name') }}</span>
											</div>

											<div class="form-group">
												<label>
													Email
													<input type="email" name="emailaddress" v-model="contact.email" class="form-control" v-validate="'required|email'" />
												</label>
												<span>{{ vErrors.first('emailaddress') }}</span>
											</div>

											<div class="form-group">
												<label>
													Country
													<select name="country" v-model="contact.country" class="form-control" v-validate="'required'">
														<option value="" disabled selected hidden>Select country</option>
														<option v-for="(item, idx) in content.FormCountries" :key="idx" :value="item">{{item}}</option>
													</select>
												</label>
												<span>{{ vErrors.first('country') }}</span>
											</div>

											<div class="form-group">
												<label>
													Message
													<textarea name="message" v-model="contact.message" class="form-control" v-validate="'required'"></textarea>
												</label>
												<span>{{ vErrors.first('message') }}</span>
											</div>

											<button type="submit" class="btn btn-default" :disabled="sending">Submit</button>

										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</modal>
		<div class="contact__content">

			<ul id="mapToggle" class="contact__navbar nav nav-tabs container" role="tablist">
				<li role="presentation" class="active">
					<a href="#officelist" aria-controls="officelist" role="tab" data-toggle="tab">{{content.ShowAsList}}</a>
				</li>
				<li role="presentation">
					<a href="#officemap" aria-controls="officemap" role="tab" data-toggle="tab">{{content.ShowOnMap}}</a>
				</li>
				<li role="presentation">
					<a href="#officecontactform" aria-controls="officecontactform" role="tab" data-toggle="tab">{{content.ContactUsForm}}</a>
				</li>
			</ul>

			<div class="tab-content contact__tabs">
				<div role="tabpanel" class="tab-pane container active" id="officelist">
					<ul>
						<li v-for="(item, idx) in countrygroups" v-bind:key="idx" v-on:click="setModalContent(item)" v-if="showGroup(item)">
							<h6>{{item.Name}}</h6><span v-if="item.Items.length > 1">{{item.Items.length}}</span>
						</li>
					</ul>
				</div>
				<div role="tabpanel" class="tab-pane" id="officemap" style="height: 100vh;">
				</div>
				<div role="tabpanel" class="tab-pane" id="officecontactform">
					<form method="post" @submit="sendMessage">

						<div class="alert alert-success" v-if="sendsuccess">
							{{smtpsuccess}}
						</div>
						<div class="alert alert-danger" v-if="sendfailure">
							{{smtperror}}
						</div>

						<p>{{content.ContactUsThankYouHeader}}</p>
						<div class="checkbox-container">
							<input type="checkbox" id="checkboxterms" name="agreeterms" v-model="agreeterms" v-validate.initial="{ isEqual: true }" />
							<label for="checkboxterms" v-html="content.ContactUsAgreeTerms">
							</label>
							<span>{{ vErrors.first('agreeterms') }}</span>
						</div>

						<div class="form-group">
							<label>
								Name
								<input type="text" name="name" v-model="contact.name" class="form-control" v-validate="'required'" />
							</label>
							<span>{{ vErrors.first('name') }}</span>
						</div>

						<div class="form-group">
							<label>
								Email
								<input type="email" name="emailaddress" v-model="contact.email" class="form-control" v-validate="'required|email'" />
							</label>
							<span>{{ vErrors.first('emailaddress') }}</span>
						</div>

						<div class="form-group">
							<label>
								Country
								<select name="country" v-model="contact.country" class="form-control" v-validate="'required'">
									<option value="" disabled selected hidden>Select country</option>
									<option v-for="(item, idx) in content.FormCountries" :key="idx" :value="item">{{item}}</option>
								</select>
							</label>
							<span>{{ vErrors.first('country') }}</span>
						</div>

						<div class="form-group">
							<label>
								Message
								<textarea name="message" v-model="contact.message" class="form-control" v-validate="'required'"></textarea>
							</label>
							<span>{{ vErrors.first('message') }}</span>
						</div>

						<div class="btn-group">
							<button type="submit" class="btn btn-default" :disabled="sending">Submit</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import VueModal from "../plugins/vue-modal";
	import apiService from "../services/apiservice";
	import { Validator } from 'vee-validate';

	Validator.extend("isEqual", {
		getMessage: field => 'required *',
		validate: value => value
	});

	export default {
		name: "ContactUs",
		props: {
			languagebranch: {
				type: String,
				required: true
			},
			contentguid: {
				type: String
			},
			smtpsuccess: {
				type: String
			},
			smtperror: {
				type: String
			}
		},
		components: {
			modal: VueModal
		},
		methods: {
			showGroup: function (item) {
				return item.Items.length > 0;
			},
			searchMe: function (e) {
				e.preventDefault();
				var self = this;

				if (self.searchtext === "") {
					self.countrygroups = self.content.Countries;
				} else {
					self.filterCountryGroups(function (item) {
						if (item.Title.search(new RegExp(self.searchtext, "i")) !== -1) return true;
						else if (item.AddressColumn1.search(new RegExp(self.searchtext, "i")) !== -1) return true;
						else if (item.AddressColumn2.search(new RegExp(self.searchtext, "i")) !== -1) return true;
						return false;
					});
				}
			},
			isActive: function (ariaName) {
				return this.selectedarea === ariaName;
			},
			sendMessage: function (e) {
				e.preventDefault();
				var self = this,
					$element = $(self.$el);

				self.$validator.validateAll().then((result) => {
					if (result && self.agreeterms) {
						self.sending = true;
						$element.spin({ color: "#000" });
						apiService.postContactForm(self.contact.name, self.contact.email, self.contact.country, self.contact.message, self.emailaddress).then(function (response) {
							$element.spin(false);
							if (response && response.data) {
								if (response.data.Success) {
									self.sendsuccess = true;
									self.sending = false;
								} else {
									self.sendfailure = true;
									self.sending = false;
								}
							} else {
								self.sendfailure = true;
								self.sending = false;
							}
						});
					}
				});
			},
			hideModal: function () {
				var self = this;
				self.modalopen = false;
				self.emailaddress = "info@nefab.com";
				$("body").removeClass("fixedPosition");
			},
			setModalContent: function (item) {
				var self = this;
				self.emailaddress = item.EmailAddress;
				self.modalcontent = item;
				self.modalopen = true;
				$("body").addClass("fixedPosition");
			},
			findOnMap: function (marker) {
				var self = this;
				self.modalopen = false;
				$("[href='#officemap']", $(self.$el)).tab("show");
				self.map.setZoom(14);
				self.map.panTo(marker.position);
				google.maps.event.trigger(marker, "click");
				$("body").removeClass("fixedPosition");
				$('#mapToggle').addClass('map');

			},
			showHideArea: function (e) {
				var self = this;
				e.preventDefault();
				self.selectedarea = e.target.dataset.area;
				self.activetitle = e.target.dataset.title;
				switch (self.selectedarea) {
					case "all":
						self.countrygroups = self.content.Countries;
						break;
					default:
						self.countrygroups = self.content.Countries.filter(function (item) { return item.GroupName === e.target.dataset.area });
						break;
				}
			},
			filterCountryGroups: function (filterCondition) {
				var self = this, countrygroups = [];
				self.content.Countries.forEach(function (country) {
					var countryCopy = Object.assign({}, country);
					countryCopy.Items = countryCopy.Items.filter(filterCondition);
					if (countryCopy.Items.length > 0) {
						var exitingItem = countrygroups.find(function (item) { return item.Name === countryCopy.Name });
						if (typeof (exitingItem) !== "undefined") {
							var mergedArray = [];
							mergedArray.pushArray(countryCopy.Items, exitingItem.Items);
							exitingItem.Items = mergedArray;
						} else {
							countrygroups.push(countryCopy);
						}
					}
				});
				self.countrygroups = countrygroups;
			}
		},
		data: function () {
			return {
				content: {},
				countrygroups: [],
				searchtext: "",
				modalcontent: {},
				modalopen: false,
				contact: {
					name: "",
					email: "",
					country: "",
					message: ""
				},
				sendsuccess: false,
				sendfailure: false,
				sending: false,
				map: null,
				selectedarea: "all",
				activetitle: "",
				agreeterms: false,
				emailaddress: "info@nefab.com"
			}
		},
		mounted: function () {
			var self = this,
				$element = $(self.$el);

			$("#mapToggle a", $element).click(function (e) {
				e.preventDefault();
				$(this).tab("show");
				$('#mapToggle').removeClass('map');
				if ($(this).attr('href') === '#officemap') {
					$('#mapToggle').addClass('map');
				}

			})

		
			$element.spin({ color: "#000" });
			apiService.getContactUs(self.contentguid, self.languagebranch).then(function (response) {
				$element.spin(false);
				if (response && response.data) {
					self.content = response.data;
					self.activetitle = self.content.ContactUsAllTitle;
					self.countrygroups = response.data.Countries;

					$.when(
						$.getScript("//maps.google.com/maps/api/js?key=AIzaSyAvZuHuoqLt03JZVHo-VBG6kmnLT9xp1rY"),
						$.getScript("//developers.google.com/maps/documentation/javascript/examples/markerclusterer/markerclusterer.js")
					).done(function () {

						var markers = [];

						self.map = new google.maps.Map(document.getElementById("officemap"), {
							zoom: 3,
							center: new google.maps.LatLng(50.430216778730525, -15.395159158858405),
							mapTypeId: google.maps.MapTypeId.ROADMAP,
							scrollwheel: false,
							mapTypeControl: false,
							panControl: false,
							zoomControl: true,
							zoomControlOptions: {
								style: google.maps.ZoomControlStyle.SMALL
							},
							scaleControl: false,
							streetViewControl: false,
							streetViewControlOptions: {
								position: google.maps.ControlPosition.LEFT_CENTER
							},
							styles: [{ "featureType": "poi", "elementType": "all", "stylers": [{ "hue": "#000000" }, { "saturation": -100 }, { "lightness": -100 }, { "visibility": "off" }] }, { "featureType": "poi", "elementType": "all", "stylers": [{ "hue": "#000000" }, { "saturation": -100 }, { "lightness": -100 }, { "visibility": "off" }] }, { "featureType": "administrative", "elementType": "all", "stylers": [{ "hue": "#000000" }, { "saturation": 0 }, { "lightness": -100 }, { "visibility": "off" }] }, { "featureType": "road", "elementType": "labels", "stylers": [{ "hue": "#ffffff" }, { "saturation": -100 }, { "lightness": 100 }, { "visibility": "off" }] }, { "featureType": "water", "elementType": "labels", "stylers": [{ "hue": "#000000" }, { "saturation": -100 }, { "lightness": -100 }, { "visibility": "off" }] }, { "featureType": "road.local", "elementType": "all", "stylers": [{ "hue": "#ffffff" }, { "saturation": -100 }, { "lightness": 100 }, { "visibility": "on" }] }, { "featureType": "water", "elementType": "geometry", "stylers": [{ "hue": "#ffffff" }, { "saturation": -100 }, { "lightness": 100 }, { "visibility": "on" }] }, { "featureType": "transit", "elementType": "labels", "stylers": [{ "hue": "#000000" }, { "saturation": 0 }, { "lightness": -100 }, { "visibility": "off" }] }, { "featureType": "landscape", "elementType": "labels", "stylers": [{ "hue": "#000000" }, { "saturation": -100 }, { "lightness": -100 }, { "visibility": "off" }] }, { "featureType": "road", "elementType": "geometry", "stylers": [{ "hue": "#bbbbbb" }, { "saturation": -100 }, { "lightness": 26 }, { "visibility": "on" }] }, { "featureType": "landscape", "elementType": "geometry", "stylers": [{ "hue": "#dddddd" }, { "saturation": -100 }, { "lightness": -3 }, { "visibility": "on" }] }]
						});

						var infowindow = new google.maps.InfoWindow(), marker, i, markericon = {
							url: "/src/images/maps_marker.png",
							size: new google.maps.Size(26, 37),
							origin: new google.maps.Point(0, 0),
							anchor: new google.maps.Point(16, 32)
						};

						response.data.Countries.forEach(function (group) {
							group.Items.forEach(function (item) {
								var lat = 0;
								var long = 0;
								var coordinates = item.Coordinates.split(',');
								if (coordinates.length == 2) {
									lat = parseFloat(coordinates[0]);
									long = parseFloat(coordinates[1]);
								}
								marker = new google.maps.Marker({
									position: new google.maps.LatLng(lat, long),
									animation: google.maps.Animation.DROP,
									map: self.map,
									icon: markericon
								});
								item.marker = marker;
								markers.push(marker);

								google.maps.event.addListener(marker, "click", (function (marker, i) {
									return function () {
										infowindow.setContent("<div class=\"row\">" +
											"<div class=\"col-xs-6\"><p><strong>" + item.Title + "</strong></p>" + item.AddressColumn1 + item.Country + "</div>" +
											"<div class=\"col-xs-6\">" + item.AddressColumn2 + item.HomeUrl + "</div>" +
											"</div>");
										infowindow.open(self.map, marker);
									}
								})(marker, i));
							});
						});

						new MarkerClusterer(self.map, markers, { imagePath: "/src/images/markercluster/m" });

					});
				}
			});
		}
	}
</script>
<style lang="scss"></style>