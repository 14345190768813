(function () {
    // Function to check if an element is visible in the viewport
    function isElementVisible(element) {
        var bounding = element.getBoundingClientRect();
        var vHeight = (window.innerHeight || document.documentElement.clientHeight)
        if (element.state === "visible") {
            return (bounding.top >= -bounding.height) && (bounding.top <= vHeight) // Check if the entire element is visible in the viewport
        } else if (element.state === "hidden") {
            return (bounding.top >= 0) && (bounding.top <= vHeight - bounding.height) // Check if elements top or bottom is visible in the viewport
        }
    }

    function resetImages(block) {
        block.classList.remove('visible-on-viewport');
    }

    function startCountdown(block) {
        block.classList.add('visible-on-viewport');
    }

    function handleScroll() {
        document.querySelectorAll('.card-container-block').forEach((e) => {
            if(e.classList.contains('single-image')) return;
            e.querySelectorAll('.card-block__images-container').forEach((block, index) => {
                if (block.state !== 'visible' && isElementVisible(block)) {
                    block.index = index;
                    block.state = 'visible';
                    startCountdown(block);
                } else if (block.state !== 'hidden' && !isElementVisible(block)) {
                    block.index = index;
                    block.state = 'hidden';
                    resetImages(block);
                }
            });
        });
    }

    function handleTouchMove() {
        window.removeEventListener('touchmove', handleTouchMove);
        window.addEventListener('scroll', handleScroll);
        setTimeout(handleScroll, 1000);
    }
    window.addEventListener('touchmove', handleTouchMove);


    var checkSingleImage = () => {
        document.querySelectorAll('.card-block').forEach((e) => {
            if (e.querySelectorAll('img').length == 1) {
                e.classList.add('single-image');
            }
        });
    }
    setInterval(checkSingleImage, 1000); // Check if there is only one image in the .cardblock if new elements is added to dom
    setInterval(checkSingleImage, 0); // After javascript first render. Check if there is only one image on .cardblock
})();
