var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "productcontent-block__content" }, [
    _c("div", { staticClass: "product__info" }, [
      _vm.selectedPage
        ? _c("h3", [_vm._v(_vm._s(_vm.selectedPage.PageName))])
        : _vm._e(),
      _vm._v(" "),
      _vm.selectedPage
        ? _c("p", [_vm._v(_vm._s(_vm.selectedPage.Excerpt))])
        : _vm._e(),
      _vm._v(" "),
      _vm.selectedPage
        ? _c(
            "button",
            {
              staticClass: "btn btn-default dropdown-toggle active active--mob",
              attrs: {
                type: "button",
                id: "productlist",
                "data-toggle": "dropdown",
                "aria-haspopup": "true",
                "aria-expanded": "true",
              },
            },
            [
              _c("div", [
                _c("div", {
                  style: {
                    backgroundImage:
                      "url('" + _vm.selectedPage.PreviewImage + "')",
                  },
                }),
              ]),
              _c("span", [_vm._v(_vm._s(_vm.selectedPage.PageName))]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "productcontent-block__mobile-only-read-more" },
        [
          _vm.selectedPage
            ? _c(
                "a",
                {
                  staticStyle: {
                    "padding-left": "1em",
                    color: "#274476",
                    "border-left": "solid #274476",
                    "font-size": "12px",
                    "font-weight": "500",
                    "text-align": "center",
                    background: "transparent",
                  },
                  attrs: { href: _vm.selectedPage.LinkUrl },
                },
                [_vm._v("Read more")]
              )
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _c(
        "ul",
        {
          staticClass: "dropdown-menu",
          attrs: { "aria-labelledby": "productlist" },
        },
        _vm._l(_vm.mappedPages, function (page, idx) {
          return _c(
            "li",
            {
              key: idx,
              class: { active: _vm.pageSelected(idx) },
              on: {
                click: function ($event) {
                  return _vm.pageClick(idx)
                },
              },
            },
            [
              _c("div", [
                _c("div", {
                  style: {
                    backgroundImage: "url('" + page.PreviewImage + "')",
                  },
                }),
              ]),
              _vm._v(" "),
              _c("span", [_vm._v(_vm._s(page.PageName))]),
              _vm._v(" "),
              _vm.pageSelected(idx)
                ? _c(
                    "a",
                    {
                      staticStyle: {
                        "padding-top": "1em",
                        "border-top": "solid #274476",
                        color: "#274476",
                        "margin-top": "1em",
                        "font-size": "12px",
                        "font-weight": "500",
                        "text-align": "center",
                        background: "transparent",
                      },
                      attrs: { href: _vm.selectedPage.LinkUrl },
                    },
                    [_vm._v("Read more")]
                  )
                : _vm._e(),
            ]
          )
        }),
        0
      ),
    ]),
    _vm._v(" "),
    _vm.selectedPage
      ? _c("div", { staticClass: "product__img" }, [
          _c("img", {
            attrs: {
              src: _vm.selectedPage.MainImage,
              alt: _vm.selectedPage.Name,
            },
          }),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }