<template>
    <div class="article-container__filter">
        <div class="dropdown">
            <button class="btn btn-default dropdown-toggle active active--mob" type="button" id="articlefilter" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true" v-if="selectedCategory">
                {{selectedCategory.Name}}
            </button>
            <ul class="dropdown-menu" aria-labelledby="articlefilter">
                <li v-for="(category, idx) in categories" v-bind:key="category.Id" v-bind:class="{ active: category.Selected }">
                    <a v-on:click="categoryClick(category)">{{category.Name}}</a>
                </li>
            </ul>
        </div>

        <div class="article-container__news">
            <div class="container">
                <div class="row" v-for="group in articleGroups">
                    <div class="col-md-4 child-page" v-for="(page, idx) in group">
                        <p v-if="page.MainImage">
                            <a :href="page.LinkUrl">
                                <img :src="page.MainImage" :alt="page.PageName">
                                <img src="/src/images/video_play.svg" alt="Video" class="playvideo" v-if="page.HasVideo" />
                            </a>
                        </p>
                        <p v-if="page.Category" class="text-muted text-uppercase">{{page.CategoryString}}</p>
                        <h3><a :href="page.LinkUrl">{{page.PageName}}</a></h3>
                        <p>{{page.Excerpt}}</p>
                        <p class="text-muted">{{page.PublishDate}}</p>
                    </div>
                </div>
            </div>
        </div>

        <span class="article-container__load-more" v-if="loadMoreEnabled">
            <button type="button" class="btn btn-default" :disabled="loadMoreDisabled" v-on:click="loadMoreClick">Load more</button>
        </span>

        <nav v-if="paginationEnabled">
            <ul class="pagination">
                <li v-bind:class="{ disabled: prevDisabled }">
                    <span v-if="prevDisabled">
                        <span aria-hidden="true">&laquo;</span>
                    </span>
                    <a href="#" @click="clickPrev" v-if="!prevDisabled"><span aria-hidden="true">&laquo;</span></a>
                </li>
                <li v-for="(page, idx) in pageRange" v-bind:key="idx" v-bind:class="{ active: page === currentPage }">
                    <a href="#" @click="changePage" :data-index="page">{{page}}</a>
                </li>
                <li v-bind:class="{ disabled: nextDisabled }">
                    <span v-if="nextDisabled">
                        <span aria-hidden="true">&raquo;</span>
                    </span>
                    <a href="#" @click="clickNext" v-if="!nextDisabled"><span aria-hidden="true">&raquo;</span></a>
                </li>
            </ul>
        </nav>
    </div>
</template>
<script>
    import apiService from "../services/apiservice";
    import { mapActions, mapGetters } from "vuex";

    export default {
        name: "ArticleListing",
        props: {
            contentguid: {
                type: String,
                required: true
            },
            languagebranch: {
                type: String,
                required: true
            },
            filtertagtitle: {
                type: String
            }
        },
        watch: {
            selectedCategoryId: function () {
                this.showArticles();
            }
        },
        computed: {
            ...mapGetters({
                selectedCategoryId: "selectedCategoryId",
                categories: "categories",
                selectedCategory: "selectedCategory"
            }),
            loadMoreEnabled: function () {
                return this.selectedCategoryId === -1;
            },
            loadMoreDisabled: function () {
                return this.currentPage === this.totalPages;
            },
            paginationEnabled: function () {
                return !this.loadMoreEnabled && this.totalPages >= 1;
            }
        },
        methods: {
            ...mapActions([
                "setCategorySelectedId",
                "setCategories"
            ]),
            categoryClick: function (category) {
                var self = this,
                    $container = $(self.$el).closest("div.article-container");
                self.currentPage = 1;
                $(".article-content-area", $container).toggleClass("hidden", !self.loadMoreEnabled);
                self.setCategorySelectedId(category.Id).then(function () { });
            },
            showArticles: function () {
                var self = this,
                    $element = $(self.$el);

                $element.spin({ color: "#000" });
                apiService.getArticles(self.contentguid, self.languagebranch, self.selectedCategoryId, self.currentPage).then(function (response) {
                    $element.spin(false);
                    if (response && response.data) {
                        self.totalPages = response.data.TotalPages;
                        self.pageRange = response.data.PageRange;
                        self.startPage = response.data.StartPage;
                        self.endPage = response.data.EndPage;
                        self.pageSize = response.data.PageSize;
                        self.prevDisabled = response.data.PageRange ? response.data.PageRange.indexOf(1) !== -1 : true;
                        self.nextDisabled = response.data.PageRange ? response.data.PageRange.indexOf(response.data.TotalPages) !== -1 : true;

                        if (self.loadMoreEnabled) {
                            var empty = [];
                            if (self.currentPage !== 1) {
                                empty = empty.concat(self.articleGroups);
                            }
                            empty = empty.concat(response.data.PagedItems);
                            self.articleGroups = empty;
                        } else {
                            self.articleGroups = response.data.PagedItems;
                        }
                    }
                });
            },
            clickPrev(e) {
                const self = this;
                e.preventDefault();

                if (self.startPage <= self.pageSize) {
                    self.startPage = 1;
                    self.endPage = self.pageSize;
                } else {
                    self.startPage -= self.pageSize;
                    self.endPage -= self.pageSize;
                }

                self.changePagination();
                self.prevDisabled = self.pageRange.indexOf(1) !== -1;
                self.nextDisabled = false;
            },
            clickNext(e) {
                const self = this;
                e.preventDefault();

                var comparePages = (self.totalPages - self.pageSize);

                if (self.startPage >= comparePages) {
                    self.startPage = comparePages;
                    self.endPage = self.totalPages;
                } else {
                    self.startPage += self.pageSize;
                    self.endPage += self.pageSize;
                }

                self.changePagination();
                self.nextDisabled = self.pageRange.indexOf(self.totalPages) !== -1;
                self.prevDisabled = false;
            },
            changePagination() {
                const self = this;
                self.pageRange.length = 0;
                for (let i = self.startPage; i <= self.endPage; i++) {
                    if (i > self.totalPages) {
                        break;
                    }
                    self.pageRange.push(i);
                }
            },
            changePage(e) {
                var self = this;
                e.preventDefault();
                self.currentPage = Number(e.target.dataset.index);
                self.showArticles();
            },
            loadMoreClick: function () {
                var self = this;
                if (self.currentPage < self.totalPages) {
                    self.currentPage = self.currentPage + 1;
                }
                self.showArticles();
            },
            breakRow: function (idx) {
                if ((idx + 1) % 3 === 0) {
                    return "</div></div><div class=\"row\">";
                }
                return "";
            }
        },
        data: function () {
            return {
                articleGroups: [],
                totalPages: 0,
                prevDisabled: true,
                nextDisabled: true,
                currentPage: 1,
                pageRange: [],
                startPage: 0,
                endPage: 0,
                pageSize: 12
            }
        },
        mounted: function () {
            var self = this,
                $element = $(self.$el);

            $element.spin({ color: "#000" });
            apiService.getArticleListingCategories(self.contentguid, self.languagebranch).then(function (response) {
                $element.spin(false);
                var categories = [];
                if (response && response.data) {
                    categories = response.data;
                }
                categories.unshift({ Id: -1, Name: self.filtertagtitle, Selected: true });
                self.setCategories(categories).then(function () {
                    self.setCategorySelectedId(-1).then(function () { });
                    self.showArticles();
                });


            });
        }
    }
</script>
<style lang="scss"></style>