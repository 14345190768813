import Cookies from "js-cookie";
import Swal from "sweetalert2";
import "waypoints/noframework.waypoints";

(function ($) {
    //Header on scroll
    let lastScrollTop = 0;
    const $body = $("body");

    $(window).scroll(function () {
        const scrollTop = $(document).scrollTop();
        const windowWidth = $(window).width();
        if (scrollTop <= 50 && windowWidth > 1024) {
            // scroll to top - not over 50px from top - desktop
            $("#header-nav").css({ top: 50 });
            $("header").removeClass("scrolled", scrollTop < 50);
        } else if (scrollTop <= 25 && windowWidth < 1024) {
            // scroll to top - not over 25px from top - mobile
            $("#header-nav").css({ top: 25 });
            $("header").removeClass("scrolled", scrollTop < 25);
        } else if (scrollTop > lastScrollTop) {
            // downscroll code
            $("#header-nav").css({ top: -50 });
            $("header").removeClass("scrolled", scrollTop < 25);
        } else {
            $("header").toggleClass("scrolled", scrollTop >= 50);
        }
        lastScrollTop = scrollTop;
    });

    if ($body.hasClass("p-bloglandingpage") || $body.hasClass("p-blogitempage")) {
        const $contactForm = $("body .form-container.form-contact"),
            $subscribeForm = $("body .form-container.form-subscribe"),
            $contactButton = $("header .btn-default"),
            $subscribeButton = $("header .btn-primary");

        $(document).on("click", "header .btn-default", function (e) {
            e.preventDefault();
            $("html, body").animate({
                scrollTop: $contactForm.first().offset().top
            }, 100);
        });

        $(document).on("click", "header .btn-primary", function (e) {
            e.preventDefault();
            $("html, body").animate({
                scrollTop: $subscribeForm.first().offset().top
            }, 100);
        });

        if ($contactForm.length > 0) {
            $contactButton.removeClass("hidden");
        }

        if ($subscribeForm.length > 0) {
            $subscribeButton.removeClass("hidden");
        }
    }

    const $epiForms = $(".EPiServerForms");
    if ($epiForms.length > 0) {
        $epiForms.each(function () {
            const $form = $(this);

            // If form has no validator, maybe form was inserted dynamically, try trigger unobtrusive
            if (!$form.data("validator")) {
                $.validator.unobtrusive.parse($form);
            }
        });

        $(document).on("submit", ".EPiServerForms", function (e) {
            const $form = $(this), $button = $("button[type='submit']", $form);
            if ($form.data("submitted") === true) {
                // Previously submitted - don't submit again
                e.preventDefault();
            } else if ($form.validate().valid()) {
                // Mark it so that the next submit can be ignored
                $form.data("submitted", true);
            }

            $button.prop("disabled", true).attr("disabled", "disabled");

            setTimeout(function () {
                if ($(".Form__Element__ValidationError:visible", $form).length > 0) {
                    $button.prop("disabled", false).removeAttr("disabled");
                }
            }, 200);
        });
    }

    $(document).on("click", ".cookie-notice #btn-accept-cookies", function () {
        Cookies.set("nefab-accept-cookies-v2", "ido", { path: "/", expires: 365 });
        const $notice = $(this).closest(".cookie-notice");
        $notice.animate({ bottom: -500 }, function () {
            $notice.addClass("hide");
        });
    });

    //If  is enabled but cookie not set, show notice
    if (Cookies.get("nefab-accept-cookies-v2", { path: "/" }) == null) {
        $(".cookie-notice").removeClass("hide");
    }

    $(document).on("click", "button.menu-button", function () {
        $(".menu-icon").toggleClass("open");
        $(".navbar.navbar-fixed-top").addClass("menu-open");
        $("body").addClass("fixedPosition");
        if (!$(".menu-icon").hasClass("open")) {
            $(".navbar.navbar-fixed-top").removeClass("menu-open");
            $("body").removeClass("fixedPosition");
        }
    });

    $("video").each(function () {
        const video = $(this)[0],
            children = Array.from(video.children);

        if (children.length > 0) {
            children.forEach(child => {
                if (child.tagName === "SOURCE" && typeof child.dataset.src !== "undefined") {
                    child.setAttribute("src", child.dataset.src);

                }
            });

            video.load();

            const videoLoad = new Promise((resolve) => {
                video.addEventListener("canplaythrough", (idx, element) => {
                    resolve(true);

                });
            });

            const videoTimeout = new Promise((resolve) => {
                setTimeout(() => {
                    resolve(false);
                }, 2000);
            });

            Promise.race([videoLoad, videoTimeout]).then(canPlay => {
                if (canPlay) {
                    setTimeout(() => {
                        video.classList.add("video-loaded");
                    }, 3000);
                } else {
                    video.removeChild(children[0]);

                    if ($("source", video).length === 0) {
                        video.controls = false;
                    } else {
                        video.load();
                    }
                }
            });
        }
    });

    const $blocks = $(".navigation-block .titleleadandimage-block");
    if ($blocks.length > 0) {
        $(document).on("click", ".navigation-block .titleleadandimage-block", function () {
            const $link = $(".titleleadandimage-block__link", $(this));

            if ($link.length === 1 && $link.text() === "") {
                location.href = $link.attr("href");
            }
        });
        $blocks.each(function () {
            const $self = $(this),
                $header = $(".titleleadandimage-block__heading", $self),
                $link = $(".titleleadandimage-block__link", $self);

            if ($link.length === 1 && $link.text() === "") {
                $link.hide().text($header.text());
                $self.addClass("clickable");
            }
        });
    }

    const waypointElement = document.querySelector(".world-map__container");
    if (typeof waypointElement !== "undefined" && waypointElement != null) {
        const waypoint = new Waypoint({
            element: waypointElement,
            handler: function () {
                $(".world-map__counter:not(.completed)").each(function () {
                    const $this = $(this), countTo = $this.attr("data-count");
                    $({ countNum: $this.text() }).animate({ countNum: countTo },
                        {
                            duration: 1000,
                            easing: "linear",
                            step: function () {
                                let value = Math.floor(this.countNum).toFixed(0);
                                if (countTo.indexOf(".") !== -1) value = parseFloat(this.countNum).toFixed(0);
                                $this.text(value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,"));
                            },
                            complete: function () {
                                $this.text(this.countNum.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,"))
                                    .addClass("completed");
                            }
                        });
                });
            },
            offset: "50%"
        });
    }

    $("section[data-image][class*='titleleadfact-block']").each(function () {
        const $self = $(this),
            staticImage = $(".titleleadfact-block__image img", $self),
            image = new Image();
        image.src = $self.data("image");
        $self.waypoint(function () {
            staticImage.attr("src", image.src);
            this.destroy(); // ser till att ladda en gång
        }, { offset: "50%" });
    });

    const $registrationForm = $("#supplierRegistrationForm"),
        $step3Message = $("#step3Error", $registrationForm);

    $(document).on("change", "#supplierRegistrationForm input[name='Step3.Accepted']", function () {
        if ($(this).val() === "false") {
            Swal.fire(undefined, $step3Message.html(), "info");
        }
    });

//BPTL-page header nav
$(document).ready(function() {
    let headerNav = $("#header-nav-bptl");
    let postsContainer = $(".bptl-posts-container");
  
    headerNav.css("marginTop", "0");
    postsContainer.css("marginTop", "0");
  });
// BPTL-page Google translate bar transitions
function checkElement() {
    let googleBar = $(".skiptranslate").first();
    let headerNav = $("#header-nav-bptl");
    let postsContainer = $(".bptl-posts-container");
  
    if (googleBar.length > 0 && googleBar.is(":visible") && !googleBar.parents(".bptl-side-nav").length) {
      headerNav.css({
        marginTop: "30px",
        transition: "margin-top 0.3s ease"
      });
      postsContainer.css({
        paddingTop: "95px",
        transition: "padding-top 0.3s ease"
      });
    } else {
      headerNav.css({
        marginTop: "0",
        transition: "margin-top 0.3s ease"
      });
      postsContainer.css({
        paddingTop: "113px",
        transition: "padding-top 0.3s ease"
      });
    }
  
    setTimeout(checkElement, 100);
  }
  checkElement();

  //BPTL-page show uploaded img in form
  $(document).ready(function () {
    $("#Images").on("change", function () {
        let container = $("#uploaded-media-container");
        container.empty();
        
        let files = $(this).prop("files");
        for (let i = 0; i < files.length; i++) {
            let file = files[i];
            let reader = new FileReader();
            
            reader.onload = function (e) {
                let img = document.createElement("img");
                img.src = e.target.result;
                
                let fileName = document.createElement("span");
                fileName.textContent = file.name;
                
                let uploadedMedia = document.createElement("div");
                uploadedMedia.className = "uploaded-media";
                uploadedMedia.appendChild(img);
                uploadedMedia.appendChild(fileName);
                
                container.append(uploadedMedia);
            };
            reader.readAsDataURL(file);
        }
    });
});

//set title on single location page
$(document).ready(function() {
    let getTitle = $(".bptl-warehouse");
    let setTitle = $(".bptl-location-title");
    setTitle.html(getTitle.html());
});
//BPTL 
$(document).ready(function() {
    //Site starts not Liked a post
    $(".like-button").on("click", function(event) {
        event.preventDefault();
        let button = $(this);
        let iconElement = button.find('i.bptl-thumb-icon');
        let contentGuid = $(this).data("content-guid");
        let actionUrl ="";
        let antiForgeryToken = $("input[name='__RequestVerificationToken']").val();
        let currentLikesCountElement = button.find('.current-likes-count');
        let likesCount = parseInt(currentLikesCountElement.text());
        let isLiked = iconElement.hasClass('bptl-green-thumb-icon');
    
        if (isLiked) {
            actionUrl ="/BptlPostPage/UnLikeAction"
            likesCount--;
            currentLikesCountElement.text(likesCount);
        } else {
            actionUrl ="/BptlPostPage/LikeAction"
            likesCount++;
            currentLikesCountElement.text(likesCount);
        }
        let buttonText = isLiked ? `<span class='current-likes-count like-number' value="${likesCount}">${likesCount}</span> people like this` : `you and <span class='current-likes-count like-number' value="${likesCount}">${likesCount}</span> people like this`;
        button.find(".bptl-block__thumb-text").html(buttonText);

        $.ajax({
            type: "POST",
            url: actionUrl,
            data: { 
                contentGuid: contentGuid,
                __RequestVerificationToken: antiForgeryToken 
            },
            success: function(data) {
            },
            error: function() {
            }
        });
        iconElement.toggleClass('bptl-green-thumb-icon');
    });
    //Site starts with liked post
    $(".unlike-button").on("click", function(event) {
        event.preventDefault();
        let button = $(this);
        let iconElement = button.find('i.bptl-green-thumb-icon-unlike');
        let contentGuid = $(this).data("content-guid");
        let actionUrl ="";
        let antiForgeryToken = $("input[name='__RequestVerificationToken']").val();
        let currentLikesCountElement = button.find('.current-unlikes-count');
        let unLikesCount = parseInt(currentLikesCountElement.text());

        let isLiked = iconElement.hasClass('bptl-thumb-icon-unlike');
        if (isLiked) {
            actionUrl ="/BptlPostPage/LikeAction"
            unLikesCount++;
            currentLikesCountElement.text(unLikesCount);
        } else {
            actionUrl ="/BptlPostPage/UnLikeAction"
            unLikesCount--;
            currentLikesCountElement.text(unLikesCount);
        }
        let buttonText = isLiked ? `you and <span class='current-unlikes-count unlike-number' value="${unLikesCount}">${unLikesCount}</span> people like this` : `<span class='current-unlikes-count unlike-number' value="${unLikesCount}">${unLikesCount}</span> people like this`;
        button.find(".bptl-block__thumb-text").html(buttonText);
        $.ajax({
            type: "POST",
            url: actionUrl,
            data: { 
                contentGuid: contentGuid,
                __RequestVerificationToken: antiForgeryToken 
            },
            success: function(data) {
            },
            error: function() {
            }
        });
        iconElement.toggleClass('bptl-thumb-icon-unlike');
    });

    //lazy loading
    const options = {
        rootMargin: '200px',
        threshold: 0.5
    };

    const imageObserver = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                const img = $(entry.target);
                entry.target.onload = function(e){
                    e.currentTarget.style.visibility="visible" 
                } 
                img.attr('src', img.attr('data-src'));
                img.removeClass('lazy-image');
                imageObserver.unobserve(entry.target);
            }
        });
    }, options);

    $('.lazy-image').each(function() {
        imageObserver.observe(this);
    });
});

})(jQuery);

$.validator.unobtrusive.adapters.addBool("mandatory", "required");

//Bring purpose to life-page (BPTL)
setTimeout(() => {
    const expandableElements = document.querySelectorAll("[data-expandable]");
    const expandableButtons = document.querySelectorAll("[data-expand-button]");
    checkForOverflow();
    expandableButtons.forEach(button => {
      button.addEventListener("click", toggleText);
      setExpandButtonText(button);
    });
    function checkForOverflow() {
      expandableElements.forEach(expandableElement => {
        if (expandableElement.classList.contains("expanded")) return;
        const expandableText = expandableElement.querySelector("[data-expand-text]");
        const overflowing = expandableText.scrollHeight > expandableText.clientHeight;
        expandableElement.dataset.overflow = overflowing;
      });
    }
    function toggleText(e) {
      const expandableElement = e.target.closest("[data-expandable]");
      const expandableText = expandableElement.querySelector("[data-expand-text]");
      if (expandableElement.classList.contains("expanded")) {
        expandableElement.classList.remove("expanded");
        expandableText.style.maxHeight = '';
      } else {
        expandableElement.classList.add("expanded");
        expandableText.style.maxHeight = `${expandableText.scrollHeight}px`;
      }
      setExpandButtonText(e.target);
    }
    function setExpandButtonText(button) {
      const expandableElement = button.closest("[data-expandable]");
      const expanded = expandableElement.classList.contains("expanded");
      const icon = expandableElement.querySelector("i.fa");
      if (expanded) {
        button.innerText = "Show less";
        icon.classList.remove("fa-plus");
        icon.classList.add("fa-minus");
      } else {
        button.innerText = "Show more";
        icon.classList.remove("fa-minus");
        icon.classList.add("fa-plus");
      }
    }

    // img scroller
    function galleryScroller() {
        var gallery = document.querySelectorAll(".gallery-scroller");
        if(gallery){
            gallery.forEach(el => {
                var speed = 500;
                var direction = "right";
                var circles = el.querySelector(".circles");
                var thumbnails = el.querySelector(".thumbnails");
                var galleryScroller = el.querySelector(".scroller");
                var currentIndex = 0;
                var intervalId;
                var startTimerTimoutId;
                var isAnimating;
        
                function init() {
                    galleryScroller.addEventListener("scroll", scrollCallback);
                    if (el.querySelector(".swiper-next")) {
                        el.querySelector(".swiper-next").addEventListener("click", scrollNext);
                    }
                    if (el.querySelector(".swiper-prev")) {
                        el.querySelector(".swiper-prev").addEventListener("click", scrollPrev);
                    }
                    createCircles();
                    createThumbnails();
                    scrollCallback();
                    startTimer();
                }
        
                function scrollCallback() {
                    stopInterval();
                    if (el.querySelector(".swiper-next")) {
                        el.querySelector(".swiper-next").classList.remove("hide");
                    }
                    if (el.querySelector(".swiper-prev")) {
                        el.querySelector(".swiper-prev").classList.remove("hide");
                    }
        
                    var r = galleryScroller.getBoundingClientRect();
                    for (let index = 0; index < galleryScroller.children.length; index++) {
                        const child = galleryScroller.children[index];
                        var xPos = child.getBoundingClientRect().x - r.x;
        
                        let childRect = child.getBoundingClientRect();
                        let parentRect = galleryScroller.getBoundingClientRect();
                        let left = childRect.left - parentRect.left;
                        let width = childRect.width;
                        let parentWidth = parentRect.width;
                        let percentage = ((left + width / 2) / parentWidth + 0.5) * 100;
                        if (percentage >= 50 && percentage <= 150) {
                            if (circles && circles.children.length > 0) {
                                circles.children[currentIndex].classList.remove("active-bptl");
                            }
        
                            if (thumbnails && thumbnails.children.length > 0) {
                                thumbnails.children[currentIndex].classList.remove("active-bptl");
                            }
                            currentIndex = index;
                        }
        
                        if (isLast() && el.querySelector(".swiper-next")) {
                            el.querySelector(".swiper-next").classList.add("hide");
                        }
        
                        if (isFirst() && el.querySelector(".swiper-prev")) {
                            el.querySelector(".swiper-prev").classList.add("hide");
                        }
                        if (circles && circles.children.length > 0) {
                            circles.children[currentIndex].classList.add("active-bptl");
                        }
        
                        if (thumbnails && thumbnails.children.length > 0) {
                            thumbnails.children[currentIndex].classList.add("active-bptl");
                        }
                    }
                    clearTimeout(startTimerTimoutId)
                    startTimerTimoutId = setTimeout(startTimer, 1000)
                }
        
                function scrollNext(e) {
                    direction = "right";
                    scrollToIndex(++currentIndex);
                }
                function scrollPrev(e) {
                    direction = "left";
                    scrollToIndex(--currentIndex);
                }
        
                function scrollToIndex(index) {
                    if (isAnimating) {
                        return;
                    }
                    index = index < 0 ? 0 : index;
                    index = index >= galleryScroller.children.length ? galleryScroller.children.length - 1 : index;
                    galleryScroller.classList.remove("snap");
                    isAnimating = true;
                    var speed = el.dataset.speed == undefined ? 500 : el.dataset.speed * 1000;
                    animateProperty(galleryScroller, "scrollLeft", galleryScroller.children[index].offsetLeft, speed, () => {
                        isAnimating = false;
                        galleryScroller.classList.add("snap");
                    });
                }
        
                function isFirst() {
                    return currentIndex == 0;
                }
        
                function isLast() {
                    return currentIndex >= galleryScroller.children.length - 1;
                }
        
                function timerCallback() {
                    if (direction == "right") {
                        if (isLast()) {
                            direction = "left";
                            timerCallback();
                        } else {
                            scrollNext();
                        }
                    } else {
                        if (isFirst()) {
                            direction = "right";
                            timerCallback();
                        } else {
                            scrollPrev();
                        }
                    }
                }
        
                function createCircles() {
                    if (!circles || galleryScroller.children.length == 1) {
                        return;
                    }
                    for (let i = 0; i < galleryScroller.children.length; i++) {
                        const div = document.createElement("div");
                        circles.appendChild(div);
                        if (i == 0) {
                            div.classList.add("active-bptl");
                        }
                        div.addEventListener("click", function (e) {
                            const index = Array.prototype.indexOf.call(circles.children, e.currentTarget);
                            currentIndex = index;
                            scrollToIndex(currentIndex);
                        });
                    }
                }
        
                function createThumbnails() {
                    if (!thumbnails || galleryScroller.children.length == 1) {
                        return;
                    }
                    for (let i = 0; i < galleryScroller.children.length; i++) {
                        var child = galleryScroller.children[i];
                        const div = document.createElement("button");
                        thumbnails.appendChild(div);
                        div.classList.add('thumbnail');
                        div.innerHTML = '<img src="' + child.querySelector('img').getAttribute('src') + '" />'
                        if (i == 0) {
                            div.classList.add("active-bptl");
                        }
                        div.addEventListener("click", function (e) {
                            const index = Array.prototype.indexOf.call(thumbnails.children, e.currentTarget);
                            currentIndex = index;
                            scrollToIndex(currentIndex);
                        });
                    }
                }
        
                function startTimer() {
                    if (el.dataset.timer != undefined && galleryScroller.children.length > 1) {
                        clearInterval(intervalId);
                        intervalId = setInterval(timerCallback, el.dataset.timer * 1 * 1000);
                    }
                }
        
                function stopInterval() {
                    clearInterval(intervalId);
                    intervalId = undefined;
                }
        
                init();
            }); 
        }

        function easeInOutCubic(x) {
            return x < 0.5 ? 4 * x * x * x : 1 - Math.pow(-2 * x + 2, 3) / 2;
        }
    
        function animateProperty(object, property, endValue, duration, onComplete) {
            let startValue = object[property];
            let startTime = performance.now();
    
            function update(currentTime) {
                let elapsed = currentTime - startTime;
                let progress = easeInOutCubic(elapsed / duration);
                progress = Math.min(progress, 1);
                object.scrollLeft = startValue + (endValue - startValue) * progress;
                if (progress < 1) {
                    requestAnimationFrame(update);
                } else if (onComplete) {
                    onComplete();
                }
            }
            requestAnimationFrame(update);
        }
    }

    galleryScroller();

    //open sideNav
    function openSideNav() {
        let button = document.querySelector(".bptl-menu-button");
        let menuIcon = document.querySelector(".bptl-menu-icon");
        let navbar = document.querySelector(".navbar.navbar-fixed-top");
        let sideMenu = document.querySelector(".bptl-side-nav");
        let isOpened = false;
      
        const content = document.querySelector('.sidebar-width-finder');
        const rect = content.getBoundingClientRect();
        const distanceToScreenSide = rect.right;
        const distanceToScreenRight = window.innerWidth - (rect.left + rect.width);
      
        let applyWidth = distanceToScreenRight - 50;
      
        button.addEventListener("click", function() {
          menuIcon.classList.toggle("bptl-open");
          navbar.classList.add("bptl-menu-open");
          toggleSideMenu();
        });
      
        document.addEventListener("click", function(event) {
          const isClickInsideMenu = sideMenu.contains(event.target);
          const isClickOnButton = button.contains(event.target);
      
          if (!isClickInsideMenu && !isClickOnButton && isOpened) {
            menuIcon.classList.toggle("bptl-open");
            navbar.classList.remove("bptl-menu-open");
            toggleSideMenu();
          }
        });
      
        function toggleSideMenu() {
          if (isOpened) {
            sideMenu.style.width = "0px";
            isOpened = false;
          } else {
            if (window.innerWidth <= 768) {
              sideMenu.style.width = "100%";
            } else if (window.innerWidth <= 1024) {
              sideMenu.style.width = "80%";
            } else {
              sideMenu.style.width = applyWidth + "px";
            }
            isOpened = true;
          }
        }
      }
    const content = document.querySelector('.sidebar-width-finder');
    if (content) {
        openSideNav();
    }
      
    //Trim published date
    let publishedDate = document.querySelectorAll(".bptl-post-block__publish-date");
    publishedDate.forEach((x) => {
        let convertedDate =  new Date( x.innerHTML)
        let formattedDate = convertedDate.toLocaleDateString();
        x.innerHTML =  formattedDate;
    })
      
    //Success message on form page
    let formButton = document.querySelector(".bptl-form__submit");
    if(formButton){
        formButton.addEventListener("click", formSuccess);
    }

    function formSuccess(){
        window.scrollTo({top: 0, behavior: 'smooth'});
        let successText = document.querySelector(".bptl-form__success");
        successText.style.display="block"
    }


    //Vimeo URL check on form page
    let videoUrlInput = document.querySelector(".bptl-form__video-url");
    if(videoUrlInput){
        videoUrlInput.addEventListener("input", validateVimeoUrl)
    }

    function validateVimeoUrl()
    {
        let  urlRaw = document.querySelector(".bptl-form__video-url");
        let inValidResponse = document.querySelector(".bptl-form__inValid-url");
        let submitButton = document.querySelector(".bptl-form__submit");

        inValidResponse.innerHTML = "";
        if (urlRaw?.value?.length > 0)
        {
            let pos = urlRaw.value.lastIndexOf('/');
            if (pos > 0)
            {
                let videoIdSegment = urlRaw.value.substr(pos + 1);
                fetch(`https://api.vimeo.com/videos/${videoIdSegment}`, {
                    method: 'GET',
                    mode: 'cors',
                    headers: {
                        'Authorization': 'Bearer c14c8adf19bfb264b150edc4468023d8'  // TODO: Replace with Nefab's token!
                    }
                })
                .then((response) => {
                    if (!response.ok) {
                        inValidResponse.innerHTML ="Invalid Vimeo URL";
                        submitButton.disabled = true
                    }
                    else {
                        inValidResponse.innerHTML ="";
                        submitButton.disabled = false;
                    }
                })
                .catch((err) =>{
                   console.log('something went wrong =', err)
                });
            }
        }
    }

}, 500);

$(function () {
    function doWeglotHide(target) {
        target = target == undefined ? document.body : target;
        var lang = $('html').attr('lang');
        if (lang) {
            $(target).find('*[class*="weglot-"]').each((i, v) => {
                var found = false;
                for (let j = 0; j < v.classList.length; j++) {
                    const className = v.classList[j];
                    if (className.startsWith("weglot-" + lang) | className == "weglot-all") {
                        found = true;
                        break;
                    }
                }
                if (!found) {
                    $(v).addClass('weglot-hide');
                } else {
                    $(v).removeClass('weglot-hide');
                }
            })
        }
    }


    new MutationObserver((mutationsList, observer) => {
        for (const mutation of mutationsList) {
            if (mutation.type === 'childList') {
                doWeglotHide(mutation.target);
            }
            else if (mutation.type === 'attributes') {
                if (mutation.attributeName == "lang" && mutation.target.tagName.toLowerCase() == "html") {
                    doWeglotHide();
                }
            }
        }
    }).observe(document, { attributes: true, childList: true, subtree: true });
    doWeglotHide();

    
});

// Menu
$(function () {
    // Header navigation
    document.querySelectorAll('.action-close-mobile-menu').forEach((v) => {
        v.addEventListener('click', (e) => {
            e.target.closest('header').classList.remove('active');
            document.body.classList.remove('lvl-1-open');
        })
    })

    document.querySelectorAll('.action-open-mobile-menu').forEach((v) => {
        v.addEventListener('click', (e) => {
            if(e.currentTarget.classList.contains('btn_open-mobile-menu') && document.body.classList.contains('lvl-1-open')) {
                e.currentTarget.closest('header').classList.remove('active');
                document.body.classList.remove('lvl-1-open');
                return;
            }
            e.currentTarget.closest('header').classList.add('active');
            document.body.classList.add('lvl-1-open');
        })
    })

    document.querySelectorAll('.lvl-1 > li > a').forEach((v) => {
        v.addEventListener('click', (e) => {
            if (!document.body.classList.contains('lvl-1-open')) {
                return;
            }

            if (e.target.closest('.lvl-2-open')) {
                document.body.classList.remove('lvl-2-open');
                e.target.closest('.nav__header').classList.remove('lvl-2-open');
                e.target.closest('li').classList.remove('active');
                e.preventDefault();
                return;
            }
            document.body.classList.add('lvl-2-open');
            e.target.closest('li').classList.add('active');
            e.target.closest('.nav__header').classList.add('lvl-2-open');
            e.preventDefault();
            return false;
        })
    })

    // Fix class placement
    var themeLead = document.querySelectorAll('.theme--lead');
    if (themeLead.length > 0) {
        var itemsDiv;
        themeLead.forEach((v) => {
            var lvl2 = v.closest('.lvl-2');
            lvl2.classList.remove('theme--lead');
            v.classList.remove('theme--lead');
            itemsDiv = lvl2.querySelector('& > .items');
            itemsDiv.classList.add('theme--lead');

             // If it doesn't contain img. Set col-3
            if(itemsDiv && !itemsDiv.querySelector('.theme--lead img')) {
                itemsDiv.classList.add('col-3');
            }
        })
    }
});

function weglotSwitcher(){
    let myDiv = document.getElementById("weglotLangList");
    let setCurrentLang = document.querySelector(".selected-lang-header")
    let textNode = setCurrentLang.childNodes[0];

    if (!Weglot) {
        return;
    }

    let availableLanguages = Weglot.options.languages.map(function(language) {
        return {
            language_to: language.language_to,
            custom_code: language.custom_code
        };
    })
    .concat(Weglot.options.language_from);

    let ulList = document.createElement("ul");
    myDiv.appendChild(ulList);

    let currentLang = Weglot.getCurrentLang();
    textNode.textContent = currentLang;

    let englishLi = document.createElement("li");
    let englishA = document.createElement("a");
    englishA.href = "javascript:Weglot.switchTo('en')";
    englishA.textContent = "English";
    englishLi.appendChild(englishA);
    ulList.appendChild(englishLi);

    for (let i = 0; i < availableLanguages.length; i++) {
        let lang = availableLanguages[i].language_to;
        let custom = availableLanguages[i].custom_code;

        if (lang === undefined || custom === undefined) {
            continue;
        }

        let li = document.createElement("li");
        let a = document.createElement("a");
        
        if (lang && custom && lang.startsWith("a")) {
            a.href = "javascript:Weglot.switchTo('" + custom + "')";
        } else {
            a.href = "javascript:Weglot.switchTo('" + lang + "')";
        }
        
        a.textContent = Weglot.getLanguageName(lang);

        li.appendChild(a);
        ulList.appendChild(li);
    }

    // Find the content of the li elements inside .lvl-1__lang and copy it so it works for mobile
    var listContent = $($('.lvl-1__lang').html());
    listContent.addClass('mobile__lang')
    $('.lvl-1').append(listContent);
}

if (typeof Weglot !== "undefined") {
    weglotSwitcher();
}
