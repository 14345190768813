$(document).ready(() => {
  if (typeof $$epiforms !== 'undefined') {
		$$epiforms(".EPiServerForms").on("formsSubmitted", (event) => {
      if (event?.type === 'formsSubmitted' ) {
        const form = event?.target

        if($(form).find('input[type="hidden"][value="generate_lead"]').length > 0) {
          dataLayer.push({event: "generate_lead"});
        } else if($(form).find('input[type="hidden"][value="newsletter_signup"]').length > 0) {
          dataLayer.push({event: "newsletter_signup"});
        }
      }
    });
  }
});