require("babel-polyfill");
import axios from "axios";

export default {
    async getProductContent(contentGuid, languageBranch) {
        try {
            return await axios.get("/Api/V2dot0/GetProductContent", {
                params: {
                    contentGuid: contentGuid,
                    languageBranch: languageBranch
                }
            });
        } catch (e) {
            return false;
        } 
    },
    async getProductImage(contentGuid, languageBranch) {
        try {
            return await axios.get("/Api/V2dot0/GetProductImage", {
                params: {
                    contentGuid: contentGuid,
                    languageBranch: languageBranch
                }
            });
        } catch (e) {
            return false;
        } 
    },
    async getArticleListingCategories(contentGuid, languageBranch) {
        try {
            return await axios.get("/Api/V2dot0/GetArticleListingCategories", {
                params: {
                    contentGuid: contentGuid,
                    languageBranch: languageBranch
                }
            });
        } catch (e) {
            return false;
        }
    },
    async getArticles(contentGuid, languageBranch, categoryId = -1, currentPage = 1) {
        try {
            return await axios.get("/Api/V2dot0/GetArticles", {
                params: {
                    contentGuid: contentGuid,
                    languageBranch: languageBranch,
                    categoryId: categoryId,
                    currentPage: currentPage
                }
            });
        } catch (e) {
            return false;
        }
    },
    async getContactUs(contentGuid, languageBranch) {
        try {
            return await axios.get("/Api/V2dot0/ContactUs", {
                params: {
                    contentGuid: contentGuid,
                    languageBranch: languageBranch
                }
            });
        } catch (e) {
            return false;
        }
    },
    async postContactForm(name, emailAddress, country, message, receiveremailaddress) {
        try {
            return await axios.post("/Api/V2dot0/ContactUsMessage", {
                name: name,
                emailAddress: emailAddress,
                country: country,
                message: message,
                receiveremailaddress: receiveremailaddress
            });
        } catch (e) {
            return false;
        }
    }
};