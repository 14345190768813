<template>
    <span>
        <a href="#" class="btn btn-default" v-if="showButton" v-on:click="playVideo">
            <span class="glyphicon glyphicon-play-circle" aria-hidden="true"></span> {{videotext}}
        </a>

        <modal :visible="modalopen" @hide="hideModal" width="800" @closemodal="hideModal">
            <div slot="content" class="embed-responsive embed-responsive-16by9">
                <video controls playsinline class="video-with-overlay__video embed-responsive-item" v-if="videofiles.length > 0">
                    <source v-for="(item, idx) in videofiles" v-bind:key="idx" :data-src="item.Url" :type="item.Type" />
                </video>
                <iframe :id="playerid" class="embed-responsive-item" :src="iframeurl" marginwidth="0" marginheight="0" scrolling="no" frameborder="0" allowfullscreen="true"></iframe>
            </div>
        </modal>
    </span>
</template>
<script>
    import VueModal from "../plugins/vue-modal";

    export default {
        name: "ProductAr",
        props: {
            videofiles: {
                type: Array,
                required: true
            },
            videotext: {
                type: String,
                required: false
            },
            youtubeid: {
                type: String
            },
            playerid: {
                type: String,
                required: true
            }
        },
        components: {
            modal: VueModal
        },
        data: function () {
            return {
                modalopen: false,
                iframeurl: ""
            }
        },
        computed: {
            showButton: function () {
                return this.videofiles.length > 0 || this.youtubeid.length > 0;
            }
        },
        methods: {
            hideModal: function () {
                if (window.youtubeplayers[this.playerid]) {
                    window.youtubeplayers[this.playerid].stopVideo();
                }

                var self = this;
                self.modalopen = false;                
            },
            playVideo: function (e) {
                e.preventDefault();
                var self = this;
                self.modalopen = true;
            }
        },
        created: function () {

        },
        mounted: function () {
            if (this.youtubeid.length > 0) {
                this.iframeurl = "".concat("//www.youtube.com/embed/", this.youtubeid, "?enablejsapi=1&amp;showinfo=0&amp;modestbranding=1&amp;rel=0");
            }
        }
    }
</script>
<style lang="scss"></style>