<template>
    <div class="productcontent-block__content">
        <div class="product-info">
            <button v-if="selectedPage" class="btn btn-default dropdown-toggle active active--mob" type="button" id="productimagelist" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                <div><div :style="{ backgroundImage: 'url(\'' + selectedPage.PreviewImage + '\')' }"></div></div><span>{{selectedPage.PageName}}</span>
            </button>
            <div class="productcontent-block__mobile-only-read-more">
                <a v-if="selectedPage" :href="selectedPage.LinkUrl" style="padding-left: 1em; color: #274476; border-left: solid #274476; font-size: 12px; font-weight: 500; text-align: center; background: transparent;">Read more</a>
            </div>
            <ul class="dropdown-menu" aria-labelledby="productimagelist">
                <li v-for="(page, idx) in mappedPages" v-bind:key="idx" v-on:click="pageClick(idx)" v-bind:class="{ active: pageSelected(idx) }">
                    <a>
                        <div><div :style="{ backgroundImage: 'url(\'' + page.PreviewImage + '\')' }"></div></div>
                        <span>{{page.PageName}}</span>
                        <a v-if="pageSelected(idx)" :href="selectedPage.LinkUrl" style="padding-top: 1em; border-top: solid #274476; color: #274476; margin-top: 1em; font-size: 12px; font-weight: 500; text-align: center;">Read more</a>
                    </a>
                </li>
            </ul>
        </div>
        <div v-if="selectedPage" class="product__img">
            <img :src="selectedPage.MainImage" :alt="selectedPage.PageName" />
        </div>
    </div>
</template>
<script>
    import apiService from "../services/apiservice";

    export default {
        name: "ProductImage",
        props: {
            contentguid: {
                type: String,
                required: true
            },
            languagebranch: {
                type: String,
                required: true
            },
            readmore: {
                type: String
            }
        },
        computed: {
            selectedPage: {
                get: function () {
                    return this.mappedPages.length > this.selectedIndex ? this.mappedPages[this.selectedIndex] : null;
                }
            }
        },
        methods: {
            pageClick: function (idx) {
                this.selectedIndex = idx;
            },
            pageSelected: function (idx) {
                return idx === this.selectedIndex;
            }
        },
        data: function () {
            return {
                mappedPages: [],
                selectedIndex: 0
            }
        },
        mounted: function() {
            var self = this,
                $element = $(self.$el);

            $element.spin({ color: "#000" });
            apiService.getProductImage(self.contentguid, self.languagebranch).then(function (response) {
                $element.spin(false);
                if (response && response.data) {
                    self.mappedPages = response.data;
                }
            });
        }
    }
</script>
<style lang="scss"></style>