<template>
    <div>
        <input type="text" v-model="searchtext" class="form-control" :id="contentguid" />
    </div>
</template>
<script>
    import "devbridge-autocomplete";
    import { mapActions, mapGetters } from "vuex";

    export default {
        name: "ArticleFilter",
        props: {
            contentguid: {
                type: String,
                required: true
            },
            languagebranch: {
                type: String,
                required: true
            }
        },
        methods: {
            ...mapActions([
                "setCategorySelectedId"
            ])
        },
        data: function () {
            return {
                searchtext: ""
            }
        },
        mounted: function () {
            var self = this;
            $("#" + self.contentguid).autocomplete({
                autoSelectFirst: true,
                serviceUrl: "/Api/V2dot0/GetArticleCategories",
                params: {
                    contentguid: self.contentguid,
                    languagebranch: self.languagebranch
                },
                dataType: "json",
                paramName: "q",
                onSelect: function (item) {
                    self.setCategorySelectedId(parseInt(item.data)).then(function () { });
                }
            });
        }
    }
</script>
<style lang="scss">
    .autocomplete-suggestions {
        border: 1px solid #999;
        background: #FFF;
        overflow: auto;
    }

    .autocomplete-suggestion {
        padding: 2px 5px;
        white-space: nowrap;
        overflow: hidden;
    }

    .autocomplete-selected {
        background: #F0F0F0;
        cursor: pointer;
    }

    .autocomplete-suggestions strong {
        font-weight: normal;
        color: #3399FF;
    }

    .autocomplete-group {
        padding: 2px 5px;
    }

        .autocomplete-group strong {
            display: block;
            border-bottom: 1px solid #000;
        }
</style>