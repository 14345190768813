var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "article-container__filter" }, [
    _c("div", { staticClass: "dropdown" }, [
      _vm.selectedCategory
        ? _c(
            "button",
            {
              staticClass: "btn btn-default dropdown-toggle active active--mob",
              attrs: {
                type: "button",
                id: "articlefilter",
                "data-toggle": "dropdown",
                "aria-haspopup": "true",
                "aria-expanded": "true",
              },
            },
            [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.selectedCategory.Name) +
                  "\n        "
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "ul",
        {
          staticClass: "dropdown-menu",
          attrs: { "aria-labelledby": "articlefilter" },
        },
        _vm._l(_vm.categories, function (category, idx) {
          return _c(
            "li",
            { key: category.Id, class: { active: category.Selected } },
            [
              _c(
                "a",
                {
                  on: {
                    click: function ($event) {
                      return _vm.categoryClick(category)
                    },
                  },
                },
                [_vm._v(_vm._s(category.Name))]
              ),
            ]
          )
        }),
        0
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "article-container__news" }, [
      _c(
        "div",
        { staticClass: "container" },
        _vm._l(_vm.articleGroups, function (group) {
          return _c(
            "div",
            { staticClass: "row" },
            _vm._l(group, function (page, idx) {
              return _c("div", { staticClass: "col-md-4 child-page" }, [
                page.MainImage
                  ? _c("p", [
                      _c("a", { attrs: { href: page.LinkUrl } }, [
                        _c("img", {
                          attrs: { src: page.MainImage, alt: page.PageName },
                        }),
                        _vm._v(" "),
                        page.HasVideo
                          ? _c("img", {
                              staticClass: "playvideo",
                              attrs: {
                                src: "/src/images/video_play.svg",
                                alt: "Video",
                              },
                            })
                          : _vm._e(),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                page.Category
                  ? _c("p", { staticClass: "text-muted text-uppercase" }, [
                      _vm._v(_vm._s(page.CategoryString)),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("h3", [
                  _c("a", { attrs: { href: page.LinkUrl } }, [
                    _vm._v(_vm._s(page.PageName)),
                  ]),
                ]),
                _vm._v(" "),
                _c("p", [_vm._v(_vm._s(page.Excerpt))]),
                _vm._v(" "),
                _c("p", { staticClass: "text-muted" }, [
                  _vm._v(_vm._s(page.PublishDate)),
                ]),
              ])
            }),
            0
          )
        }),
        0
      ),
    ]),
    _vm._v(" "),
    _vm.loadMoreEnabled
      ? _c("span", { staticClass: "article-container__load-more" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-default",
              attrs: { type: "button", disabled: _vm.loadMoreDisabled },
              on: { click: _vm.loadMoreClick },
            },
            [_vm._v("Load more")]
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.paginationEnabled
      ? _c("nav", [
          _c(
            "ul",
            { staticClass: "pagination" },
            [
              _c("li", { class: { disabled: _vm.prevDisabled } }, [
                _vm.prevDisabled
                  ? _c("span", [
                      _c("span", { attrs: { "aria-hidden": "true" } }, [
                        _vm._v("«"),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                !_vm.prevDisabled
                  ? _c(
                      "a",
                      { attrs: { href: "#" }, on: { click: _vm.clickPrev } },
                      [
                        _c("span", { attrs: { "aria-hidden": "true" } }, [
                          _vm._v("«"),
                        ]),
                      ]
                    )
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _vm._l(_vm.pageRange, function (page, idx) {
                return _c(
                  "li",
                  { key: idx, class: { active: page === _vm.currentPage } },
                  [
                    _c(
                      "a",
                      {
                        attrs: { href: "#", "data-index": page },
                        on: { click: _vm.changePage },
                      },
                      [_vm._v(_vm._s(page))]
                    ),
                  ]
                )
              }),
              _vm._v(" "),
              _c("li", { class: { disabled: _vm.nextDisabled } }, [
                _vm.nextDisabled
                  ? _c("span", [
                      _c("span", { attrs: { "aria-hidden": "true" } }, [
                        _vm._v("»"),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                !_vm.nextDisabled
                  ? _c(
                      "a",
                      { attrs: { href: "#" }, on: { click: _vm.clickNext } },
                      [
                        _c("span", { attrs: { "aria-hidden": "true" } }, [
                          _vm._v("»"),
                        ]),
                      ]
                    )
                  : _vm._e(),
              ]),
            ],
            2
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }