import articleActions from "./actions.articlelisting";
import articleMutations from "./mutations.articlelisting";

export const getDefaultArticleState = () => {
    return {
        selectedCategoryId: -1,
        categories: [],
        selectedCategory: {}
    };
};

// initial state
const state = getDefaultArticleState();

// getters
const getters = {
    selectedCategoryId: state => state.selectedCategoryId,
    categories: state => state.categories,
    selectedCategory: state => state.selectedCategory
};

export default {
    state: state,
    getters: getters,
    actions: articleActions,
    mutations: articleMutations
};